import colors from "../../base/colors";

const { white } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
};
