import borders from "theme/base/borders";
import typography from "theme/base/typography";
import pxToRem from "theme/functions/pxToRem";
import { XL } from "../container";


const { fontWeightMedium, size } = typography;
const { borderRadius } = borders;
const root = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: size.sm,
  fontWeight: fontWeightMedium,
  borderRadius: borderRadius.md,
  padding: `${pxToRem(10)} ${pxToRem(15)}`,
  lineHeight: 1.4,
  textAlign: 'center',
  textTransform: 'unset',
  userSelect: 'none',
  backgroundSize: '150% !important',
  backgroundPositionX: '25% !important',
  transition: `all 150ms ease-in`,
  [XL]: {
    fontSize: size.regular,
  },
  '&:disabled, &.Mui-disabled': {
    borderWidth: '2px',
    pointerEvent: 'none',
    opacity: 0.65,
  },

  '& .material-icons': {
    fontSize: pxToRem(15),
    marginTop: pxToRem(-2),
  },
};
export default root;
