
import { Box, FormHelperText, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

const PasswordInput = forwardRef(({ showValidationList, value, ...rest }, ref) => {
  const [show, setShow] = useState(false);
  const paswordToggler = () => setShow(prev => !prev);

  const checkPasswordRequirements = (password) => {
    const newPasswordRequirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    return newPasswordRequirements;
  };
  return (
    <>
      <TextField
        {...rest}
        name={rest.id}
        type={show ? "text" : "password"}
        fullWidth
        inputRef={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton size="small" disableRipple onClick={paswordToggler}>
                <i className={`feather-password feather-eye${!show ? "-off" : ""}`} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {showValidationList ? (
        <Box pt={1}>
          {Object.entries(checkPasswordRequirements(value || "")).map(([key, objVelue]) => (
            <Box key={key} display={"flex"} justifyContent={'start'} alignItems={'center'} columnGap={0.5}>
              <Typography component={'span'} lineHeight={0} sx={{ verticalAlign: 'middle', '& svg': { mt: 0.5 } }}
                fontSize={14} color={objVelue ? "primary" : value ? "error" : 'text.light'}>
                {objVelue ?
                  <DoneAllRoundedIcon fontSize='inherit' /> :
                  value ? <CloseRoundedIcon fontSize='inherit' />
                    : <FiberManualRecordRoundedIcon sx={{ fontSize: 8 }} />}
              </Typography>
              <FormHelperText error={Boolean(!objVelue && value)} sx={{ color: objVelue ? 'primary.main' : 'text.light' }}>
                {key === "minLength"
                  ? "Has at least 8 characters."
                  : key === "uppercase"
                    ? "Includes a capital letter."
                    : key === "lowercase"
                      ? "Includes a small letter."
                      : key === "number"
                        ? "Includes a number."
                        : key === "specialChar"
                          ? "Includes a symbol."
                          : ""}
              </FormHelperText>
            </Box>
          ))}

        </Box >
      ) : null}
    </>
  )
})

export default PasswordInput;
