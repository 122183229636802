import Preloader from "common/preloader";
import useLogout from "./useLogout";
import { useEffect } from "react";

const Logout = () => {
  const logout = useLogout();
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Preloader custonClass="auth" />
}

export default Logout;