import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { stringAvatar, capitalize } from "helper";
import profileImage from "assets/images/profile.png";
import { adminPanelInfo } from "common/websiteData";
import ProfileInfoCard from "components/cards/ProfileInfoCard";
import { ProfileDetailsStyled } from "components/cards/style";
import { useAdminProfileQuery } from "app/features/apis/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { logoutFn } from "pages/authentication/logout/useLogout";
import { useNavigate } from "react-router-dom";

const ProfileDetails = () => {
  const { isOffline } = useSelector(state => state.uiCtrl);

  const suiSnackbar = useSuiSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, isError, error } = useAdminProfileQuery();
  if (error) {
    if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
      suiSnackbar("Internal server error!");
    } else if (error?.originalStatus === 503) {
      suiSnackbar("Service Unavailable!");
    } else if (error?.data) {
      if ([403].includes(error?.data.statusCode)) {
        logoutFn(navigate, dispatch);
      }
      suiSnackbar(error?.data?.message);
    } else {
      suiSnackbar(isOffline ? "Check your Internet connection and try again!" : "Something went wrong!");
    }
  }
  const fullName = data?.data.admin_name ? `${data?.data.first_name} ${data?.data.last_name}` : adminPanelInfo.appName;
  const loading = isError || isLoading || isFetching;
  return (
    <ProfileDetailsStyled>
      <Box className={'avatar_box'}>
        {loading ? (
          <Skeleton variant="circular">
            <Avatar sx={{ width: "120px", height: "120px" }} />
          </Skeleton>
        ) : (
          <Avatar
            src={profileImage}
            alt={fullName}
            {...stringAvatar(fullName, profileImage, 120, 40)}
          />
        )}
        <Box className={'name_box'}>
          <Typography variant="h4">
            {loading ? <Skeleton height={40} width={160} /> : capitalize(fullName)}
          </Typography>
          <Typography variant="h6" color="text.light">
            {loading ? <Skeleton height={25} width={80} /> : "Super Admin"}
          </Typography>
        </Box>
      </Box>
      <Box mt={2} mb={3}>
        {loading ? (
          <>
            <Skeleton height={35} width={200} sx={{ marginBottom: "20px" }} />
            <Skeleton height={20} width={180} />
            <Skeleton height={20} width={165} />
            <Skeleton height={20} width={165} />
            <Skeleton height={20} width={120} />
            <Skeleton height={20} width={160} />
            <Skeleton height={20} width={100} />
          </>
        ) : (
          <ProfileInfoCard
            title="profile information"
            info={{
              mobile: data?.data.phone_number || "+1 (647) 629-0966",
              email: data?.data.email || "admin@email.com",
            }}
          />
        )}
      </Box>
    </ProfileDetailsStyled>
  );
};

export default ProfileDetails;
