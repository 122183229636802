import { NavLink } from "react-router-dom";

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { ListItemIcon, ListItemText } from "@mui/material";
import { SubLinkStyled } from "../style";


const SubLink = ({ to, name }) => {
  return (
    <SubLinkStyled component={NavLink} to={to} className={'collapse_item'}>
      <ListItemIcon className={'collapse_iconBox'}>
        <CircleRoundedIcon fontSize="18px" className="active-icon" sx={{ fill: "#ED952F" }} /> 
        <CircleOutlinedIcon fontSize="18px" className="icon" />
      </ListItemIcon>
      <ListItemText primary={name} classes={{ root: 'collapse_text' }} />
    </SubLinkStyled>
  );
}

export default SubLink;
