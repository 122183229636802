import { Breadcrumbs as MuiBreadcrumbs, styled } from "@mui/material";
export const MuiBreadcrumbsStyled = styled(MuiBreadcrumbs)(({ theme }) => {
  const { palette } = theme;
  return {
    "&> .MuiBreadcrumbs-ol": {
      flexWrap: "nowrap",
    },
    "& .MuiBreadcrumbs-separator": {
      color: palette.grey[600],
    },
  }
});