import { baseAPIService } from ".";

const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    adminLogin: build.mutation({
      query: (data) => {
        return {
          url: "admin/login",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_PROFILE"],
    }),
    adminLogout: build.mutation({
      query: () => {
        return {
          url: "admin/logout",
          method: "POST",
        };
      },
    }),
    adminForgotPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/forgotPassword",
          method: "POST",
          body: data,
        };
      },
    }),
    adminVerifyOtp: build.mutation({
      query: ({ data, token }) => {
        return {
          url: "admin/verifyOtp",
          method: "POST",
          headers: {
            authtoken: token,
          },
          body: data,
        };
      },
    }),
    adminResetPassword: build.mutation({
      query: ({ data, token }) => {
        return {
          url: "admin/resetPassword",
          method: "PATCH",
          headers: {
            authtoken: token,
          },
          body: data,
        };
      },
    }),
    adminChangePassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/changePassword",
          method: "PATCH",
          body: data,
        };
      },
    }),
    adminProfile: build.query({
      query: () => {
        return {
          url: "admin/profile",
        };
      },
      keepUnusedDataFor: 1200,
      providesTags: ['GET_PROFILE'],
    }),
  }),
  overrideExisting: true,
});
export const {
  useAdminLoginMutation,
  useAdminLogoutMutation,
  useAdminForgotPasswordMutation,
  useAdminVerifyOtpMutation,
  useAdminResetPasswordMutation,
  useAdminChangePasswordMutation,
  useAdminProfileQuery,
} = AuthApi;
