import { useAdminProfileQuery } from "app/features/apis/AuthApi";
import { ProfilerStyled } from "./style";
import { Avatar, Box, Menu, MenuItem, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { stringAvatar } from "helper";
import profileImage from 'assets/images/profile.png';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useState } from "react";
import colors from "theme/base/colors";
import { Link, useNavigate } from "react-router-dom";
import useLogout, { logoutFn } from "pages/authentication/logout/useLogout";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useDispatch, useSelector } from "react-redux";

const ProfilerLoader = () => (
  <Box display={'inline-flex'} columnGap={'10px'} alignItems={'center'}>
    <Skeleton variant="circular" width={40} height={40} />
    <Skeleton variant="text" width={100} sx={{ fontSize: '1.5rem' }} />
  </Box>
)

const Profiler = () => {
  const { isOffline } = useSelector(state => state.uiCtrl);
  const theme = useTheme();
  const suiSnackbar = useSuiSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const logoutHandler = useLogout();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { isLoading, isFetching, isError, error } = useAdminProfileQuery();
  if (error) {
    if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
      suiSnackbar("Internal server error!");
    } else if (error?.originalStatus === 503) {
      suiSnackbar("Service Unavailable!");
    } else if (error?.data) {
      if ([403].includes(error?.data.statusCode)) {
        logoutFn(navigate, dispatch);
      }
      suiSnackbar(error?.data?.message);
    } else {
      suiSnackbar(isOffline ? "Check your Internet connection and try again!" : "Something went wrong!");
    }
  }
  return (
    <ProfilerStyled>
      {(isLoading || isFetching || isError)
        ? <ProfilerLoader />
        : (
          <>
            <Box display={'inline-flex'} columnGap={'10px'} alignItems={'center'} sx={{ cursor: 'pointer', userSelect: 'none' }} onClick={handleClick}>
              <Avatar
               src={profileImage}
               alt={"royal badmintonclub"} 
              {...stringAvatar("BT", profileImage)}
               />
              {!mobile && (
                <>
                  <Typography variant="body2" mr={'-10px'} component={'span'} fontWeight={600}>
                    Super Admin
                  </Typography>
                  <KeyboardArrowDownRoundedIcon sx={{ mt: '1px' }} fontSize="22px" />
                </>
              )}
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              autoFocus={false}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem
                component={Link}
                sx={{ "&:hover": { bgcolor: colors.info.main, color: "#fff" } }}
                to="/profile"
              >
                My Account
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#FC3C3C",
                  "&:hover": { bgcolor: "#FC3C3C", color: "#fff" },
                }}
              onClick={logoutHandler}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        )
      }
    </ProfilerStyled>
  )
}

export default Profiler;