import { Box, Card, styled } from "@mui/material";

export const CollapseFormLayoutStyled = styled(Card)(({ theme }) => {
  const { breakpoints, functions } = theme;
  const { pxToRem } = functions;
  return {
    margin: `${pxToRem(16)} 0 ${pxToRem(16 * 3)}`,
    padding: pxToRem(16),
    paddingRight: 0,
    minHeight: "450px",
    [breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingTop: 0,
    },
    '& .side_card_Box': {
      boxShadow: "none",
      backgroundColor: "#F2F4FF",
      height: "100%",
      minHeight: "418px",
      overflow: "hidden",
      padding: pxToRem(10),
      [breakpoints.down("sm")]: {
        minHeight: "unset",
        padding: pxToRem(8),
        borderRadius: "0.7rem 0.7rem 0.3rem 0.3rem",
      },
    },
    '& .form_box': {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      overflowY: "overlay",
      height: "100%",
    },
  }
});

export const ProfileInfoCardStyled = styled(Box)(() => {
  return {
    '& .labels': {
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    '& .values': {
      fontWeight: "400",
      textTransform: "unset",
    },
  }
});

export const ProfileDetailsStyled = styled(Box)(({ theme }) => {
  const { breakpoints, functions } = theme;
  const { pxToRem } = functions;
  return {
    '& .avatar_box': {
      display: "flex",
      alignItems: "center",
      [breakpoints.down("sm")]: {
        flexFlow: "column wrap",
        alignItems: "flex-start",
      },
    },
    '& .name_box': {
      paddingLeft: `${pxToRem(16)}`,
      paddingTop: `${pxToRem(16)}`,
    },
  }
});