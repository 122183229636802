import { AccordionDetails, AccordionSummary, Box, ListItemIcon, ListItemText } from "@mui/material";
import { useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CollapseItem, SideNavTitle } from "../style";
import SubLink from "./SubLink";

const SidenavCollapse = ({ icon, name, subMenu }) => {
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  let active = subMenu.map(({ link }) => {
    if (link === `/${collapseName}`) {
      return true;
    }
    return false;
  });
  active = active.includes(true);
  const [expanded, setExpanded] = useState(active);
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <CollapseItem
      expanded={expanded}
      onChange={handleChange(!expanded)}
    >
      <AccordionSummary className={`collapse_summary ${active ? 'active' : ''}`} expandIcon={<ExpandMoreIcon />}>
        <ListItemIcon className={'collapse_iconBox'}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={name} classes={{ root: 'collapse_text' }} />
      </AccordionSummary>
      <AccordionDetails sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {subMenu.map(({ id, order, label, link, type }) => {
          return (
            <Fragment key={id}>
              {type !== "title" ? (
                <Box sx={{ order: order }}>
                  <SubLink to={link} name={label} />
                </Box>
              ) : (
                <SideNavTitle sx={{ order: order }} variant="caption">
                  - {label} -
                </SideNavTitle>
              )}
            </Fragment>

          );
        })}
      </AccordionDetails>
    </CollapseItem>
  );
};

export default SidenavCollapse;
