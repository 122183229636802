import { Box, Divider, IconButton, List, ListItem, useMediaQuery, useTheme } from "@mui/material";
import { adminPanelInfo, sidenavData } from "common/websiteData";
import { SideNav, SideNavTitle } from "./style";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect } from "react";
import SidenavLink from "./components/SidenavLink";
import SidenavCollapse from "./components/SidenavCollapse";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "app/features/uiCtrlSlice";
import { useLocation } from "react-router-dom";

const Sidenav = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("xl"));
  const { menu } = useSelector((state => state.uiCtrl));
  const dispatch = useDispatch();
  useEffect(() => {
    if (tabView) {
      dispatch(setMenu(false));
    }
  }, [dispatch, tabView]);

  useEffect(() => {
    dispatch(setMenu(false));
  }, [dispatch, pathname]);

  const renderRoutes = sidenavData.map(({ id, icon, link, label, sub_menu, type }) => {
    let returnValue;
    if (type === "link") {
      returnValue = <SidenavLink to={link} name={label} icon={icon} />
    } else if (type === "collapse") {
      returnValue = <SidenavCollapse name={label} icon={icon} subMenu={sub_menu} />;
    } else if (type === "title") {
      returnValue = (
        <SideNavTitle variant="caption">
          {label}
        </SideNavTitle>
      );
    } else if (type === "divider") {
      returnValue = <Divider />;
    }
    return (
      <ListItem component="li" key={`sidenav_${id}`} sx={{ order: id, py: 0.5 }}>
        {returnValue}
      </ListItem>
    );
  });

  return (
    <SideNav
      variant="permanent"
      component={'aside'}
      classes={{
        paper: !tabView || menu ? 'open' : 'close',
      }}
    >
      <Box className={'sidenav_header'}>
        <Box
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
        >
          <IconButton onClick={() => dispatch(setMenu(false))} size="small" color="text.main">
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <Box
          component="img"
          src={adminPanelInfo.logoPrimary}
          alt={adminPanelInfo.appName}
          className={'sidenav_logo img-fluid'}
        />
      </Box>
      <Divider />
      <List className={'sidenav_list'}>{renderRoutes}</List>
    </SideNav>
  );
};

export default Sidenav;
