import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import notFound from 'assets/images/404.png';

const NotFound = ({ buttonText = "Back to Dashboard", to = "/dashboard" }) => {
  return (
    <Box py={{ xs: '40px', lg: '60px' }} component={'section'}>
      <Container>
        <Box maxWidth={700} mx={'auto'}>
          <img className="img-fluid" width={1032} height={520} src={notFound} alt={'not found 404'} />
          <Typography component={'p'} variant="body2" textAlign={'center'} my={4} fontSize={{ xs: 16, md: 18, lg: 20 }}>
            The page you are looking for might have been removed, had its name changed,
            or is temporarily unavailable.
          </Typography>
          <Box textAlign={'center'}>
            <Button component={Link} to={to} size="large" color="primary" variant="contained"
              endIcon={<i className="feather-arrow-right-circle" />}>
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default NotFound;