import { ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { SideNavLinkItem } from "../style";

const SidenavLink = ({ icon, to, name }) => {
  return (
    <SideNavLinkItem
      component={NavLink}
      to={to}
      className={'nav-item'}
      alignItems="center"
    >
      <ListItemIcon className={'iconBox'}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={name} classes={{ root: 'nav-text' }} />
    </SideNavLinkItem>
  );
}

export default SidenavLink;
