/* eslint-disable no-undef */
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import pxToRem from 'theme/functions/pxToRem';
import AWS from "aws-sdk";

const isColorDark = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance < 0.6;
}

const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

const generateHexColor = (str) => {
  const colorValue = Math.abs(hashCode(str)) % 16777215; // 16777215 is FFFFFF in hex

  const hexColor = '#' + colorValue.toString(16).padStart(6, '0');

  const isDarkColor = isColorDark(hexColor);

  const fontColor = isDarkColor ? "#fff" : "#000";

  return [hexColor, fontColor];
}

export const capitalize = (word) => {
  return word
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    })
    ?.join(" ");
};

export const stringAvatar = (name, image, size = 40, font = 17, sx = {}) => {
  const [bgcolor, color] = generateHexColor(name);
  return {
    sx: {
      bgcolor: image ? "transparent" : bgcolor,
      color: color,
      width: pxToRem(size),
      height: pxToRem(size),
      fontSize: pxToRem(font),
      ...sx
    },
    src: image,
    children: `${(name?.split(" ")[0][0] || "").toUpperCase()}${(
      name?.split(" ")[name?.split(" ").length > 1 ? 1 : 0][0] || ""
    ).toUpperCase()}`,
  };
};

export const removeHtmlTags = (inputString) => {
  return inputString ? inputString.replace(/<[^>]*>/g, '') : true;
}

export const getLocalStorage = (val = '') => {
  const value = localStorage.getItem(val);
  return value
}

export const appendZero = (number) => {
  if (number < 10) {
    return `0${+number}`;
  }
  return number;
};

export const ResetScroll = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const html = document.documentElement;
    window.scrollTo(0, 0);
    html.style.scrollBehavior = "unset";
    return () => {
      html.style.scrollBehavior = "smooth";
    }
  }, [pathname]);
  return null;
};

export function setCurrentTime() {
  const currentTime = dayjs();
  return currentTime;
}

function formatTime(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return [minutes, remainingSeconds];
}

export function startCountdown() {
  const currentTime = localStorage.getItem('sendOTPtime');
  if (!currentTime) {
    return [0, 0];
  }
  const targetTime = dayjs(currentTime).add(4, 'minute').add(59, 'seconds');
  const now = dayjs();
  const remainingTime = targetTime.diff(now);

  if (remainingTime <= 0) {
    return [0, 0]
  } else {
    return formatTime(remainingTime);
  }
}

export const uploadFileToS3 = async (file, type, date) => {
  try {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
    });

    const name = file.name.split(" ").join("_");
    const fileName = type + date + name;
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName.toLowerCase(),
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    return [params, s3];
  } catch (error) {
    console.error("Error initializing S3:", error);
  }
};

export const getNewObjectProperties = (prevObj, newObj) => {
  const results = prevObj.filter((obj) => {
    return !newObj.some((obj2) => {
      return JSON.stringify(obj) === JSON.stringify(obj2);
    });
  });
  return results;
};

export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (!dayjs(prevObj[key]).isSame(newObj[key]) && prevObj[key] !== newObj[key]) {
      if (newObj[key]?.length > 0 || !isNaN(newObj[key]) || newObj[key]) {
        if (typeof newObj[key] === 'string') {
          resultObject[key] = newObj[key].trim();
        } else {
          resultObject[key] = newObj[key];
        }
      } else {
        resultObject[key] = null;
      }
    }
  }
  if (resultObject["phone_number"] || resultObject["iso_country_code"]) {
    resultObject["iso_country_code"] = newObj.iso_country_code;
    resultObject["country_code"] = newObj.country_code;
    resultObject["phone_number"] = newObj.phone_number;
  }
  if (resultObject["emergency_phone_number"] || resultObject["iso_emergency_country_code"]) {
    resultObject["iso_emergency_country_code"] = newObj.iso_emergency_country_code;
    resultObject["emergency_country_code"] = newObj.emergency_country_code;
    resultObject["emergency_phone_number"] = newObj.emergency_phone_number;
  }
  return resultObject;
};

export const checkIfEmpty = (value) => {
  if (value && value.length > 0) {
    return value;
  }
  return null;
};

export const getValueByKey = (arr, searchValue, compareKey, returnKey) => {
  const foundItem = arr?.find(item => item[compareKey] === searchValue);

  if (foundItem) {
    return foundItem[returnKey];
  } else {
    return null;
  }
};

export const commonErrorShowFn = (error, suiSnackbar, isOffline) => {
  if (error) {
    if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
      suiSnackbar("Internal server error!");
    } else if (error?.originalStatus === 503) {
      suiSnackbar("Service Unavailable!");
    } else if (error?.data) {
      suiSnackbar(error?.data.message);
    } else {
      suiSnackbar(isOffline ? "Check your Internet connection and try again!" : "Something went wrong!");
    }
  }
};

export const setFeildBySearchParams = (formik, getSearchParams) => {
  for (const key in getSearchParams) {
    const value = getSearchParams[key];
    formik.setFieldValue(key, isNaN(+value) ? value : +value);
  }
};

export const convertTimeFormet = (time, add = null) => {
  const [hour, minute] = time.split(':');
  if (add) {
    const added = (+hour + +add)
    const addHour = added < 24 ? +hour + add : added - 24;
    const newHour = addHour <= 12 ? appendZero(addHour) : appendZero(addHour - 12);
    const getA = addHour < 12 ? 'AM' : 'PM';
    const newTime = `${newHour == 0 ? 12 : newHour}:${minute} ${getA}`;
    return newTime;
  } else {
    const newHour = hour <= 12 ? appendZero(hour) : appendZero(hour - 12);
    const getA = hour < 12 ? 'AM' : 'PM';
    const newTime = `${newHour == 0 ? 12 : newHour}:${minute} ${getA}`;
    return newTime;
  }
};

export const breadcrumbsHeadingGen = (str) => {
  let splitArray = str
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    });

  for (let i = 0; i < splitArray.length; i++) {
    if (splitArray[i] === "Port") {
      splitArray[i] = "Port-in";
      splitArray.splice(i + 1, 1);
    } else if (splitArray[i] === "Did") {
      splitArray[i] = "DID";
    } else if (splitArray[i] === "Dids") {
      splitArray[i] = "DIDs";
    } else if (splitArray[i] === "Sip") {
      splitArray[i] = "SIP";
    } else if (splitArray[i] === "Cdr") {
      splitArray[i] = "CDR";
    }
  }
  const resultString = splitArray.join(" ");
  return resultString;
};

export const modifyDates = (searchObject = {}, keysToModify = []) => {
  keysToModify.forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (searchObject[key]) {
      searchObject[key] = dayjs(searchObject[key]).format("YYYY-MM-DD");
    }
  });

  // Return the modified or unmodified object
  return searchObject;
}