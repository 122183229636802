import { AppBar, Box, styled } from "@mui/material";

export const AppBarStyled = styled(AppBar)(({ theme }) => {
  const { palette, functions, breakpoints, transitions } = theme;
  const { white, text } = palette;
  const { rgba, pxToRem } = functions;
  return {
    backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
    backgroundColor: rgba(white.main, 0.8),
    color: text.main,
    minHeight: pxToRem(70),
    display: "grid",
    alignItems: "center",
    padding: pxToRem(8),
    paddingLeft: pxToRem(16),
    [breakpoints.up("xl")]: {
      width: `calc(100% - ${pxToRem(250)})`,
      transition: transitions.create(["width"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    "&> *": {
      transition: transitions.create("all", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 0,

      [breakpoints.up("sm")]: {
        minHeight: "auto",
      },
    },
    "&+ .MuiToolbar-root": {
      minHeight: pxToRem(70),
    },
  }
});

export const ProfilerStyled = styled(Box)(() => {
  return {
    
  }
})