import { Accordion, Box, Drawer, Typography, styled } from "@mui/material";

export const SideNav = styled(Drawer)(({ theme }) => {
  const { functions, palette, transitions, breakpoints, boxShadows } = theme;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;
  const { white, transparent } = palette;
  const sidebarWidth = 250;
  return {
    '&> .MuiPaper-root': {
      boxShadow: xxl,
      border: "none",
      [breakpoints.up("xl")]: {
        backgroundColor: white.main,
        boxShadow: "none",
        marginBottom: "inherit",
        left: 0,
      },
      '&.open': {
        width: 320,
        transform: "translateX(0)",
        transition: transitions.create("transform", {
          easing: transitions.easing.sharp,
          duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
          width: sidebarWidth,
          transform: "translateX(0)",
          transition: transitions.create(["width", "background-color"], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
          }),
        },
      },

      '&.close': {
        width: 320,
        transform: `translateX(${pxToRem(-320)})`,
        transition: transitions.create("transform", {
          easing: transitions.easing.sharp,
          duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
          width: pxToRem(96),
          overflowX: "hidden",
          transform: "translateX(0)",
          transition: transitions.create(["width", "background-color"], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
          }),
        },
      },
    },
    '& .sidenav_list': {
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh -150px)",
      overflowY: "overlay",
      "&::-webkit-scrollbar": {
        width: pxToRem(4),
      },
      "&::-webkit-scrollbar-track": {
        background: transparent.main,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#88888899",
        opacity: 0.5,
        borderRadius: "10px",
        "&:hover": {
          background: "#555555aa",
          opacity: 1,
        },
      },
    },
    '& .sidenav_header': {
      "& a": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
      },
    },
    '& .sidenav_logo': {
      margin: '0.5rem 0 -0.5rem 1rem',
      width: pxToRem(168),
    }
  }
});

export const SideNavTitle = styled(Typography)(({ theme }) => {
  const { functions, palette } = theme;
  const { pxToRem } = functions;
  return {
    fontWeight: 700,
    textTransform: 'uppercase',
    display: "block",
    color: palette.text.light,
    paddingLeft: pxToRem(8),
    margin: `${pxToRem(16)} 0 ${pxToRem(8)} ${pxToRem(8)}`,
  }
});

export const SideNavLinkItem = styled(Box)(({ theme }) => {
  const { functions, palette, breakpoints, borders, boxShadows, transitions } = theme;
  const { pxToRem } = functions;
  const sidenavColor = 'warning';

  return {
    backgroundColor: palette.transparent.main,
    color: palette.text.light,
    display: "flex",
    width: "100%",
    padding: `${pxToRem(10.8)} ${pxToRem(8)}}`,
    margin: `0 ${pxToRem(8)}`,
    borderRadius: borders.borderRadius.md,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: "none",
    transition: transitions.create("all", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      '&.active': {
        boxShadow: boxShadows.xxl,
      },
      transition: transitions.create("box-shadow", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
    '& .MuiListItemIcon-root.iconBox': {
      backgroundColor: palette.light.main,
      minWidth: pxToRem(32),
      minHeight: pxToRem(32),
      borderRadius: borders.borderRadius.md,
      display: "grid",
      placeItems: "center",
      boxShadow: boxShadows.regular,
      transition: transitions.create("margin", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
      "& svg, svg g": {
        width: 19,
        fill: palette.text.light,
      },
    },
    '& .nav-text': {
      marginLeft: pxToRem(8),

      [breakpoints.up("xl")]: {
        opacity: 1,
        maxWidth: "100%",
        marginLeft: pxToRem(8),
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      "& span": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 0,
        color: palette.text.main,
      },
    },
    '&.active': {
      backgroundColor: palette.light.main,
      color: palette.text.main,
      '& .MuiListItemIcon-root.iconBox': {
        backgroundColor: palette[sidenavColor].main,
        "& svg, svg g": {
          fill: palette.light.main,
        },
      },
      '& .nav-text span': {
        fontWeight: 600,
      },
    },
  }
});

export const CollapseItem = styled(Accordion)(({ theme }) => {
  const { functions, palette, breakpoints, borders, boxShadows, transitions } = theme;
  const { pxToRem } = functions;
  const sidenavColor = 'warning';
  return {
    backgroundColor: palette.transparent.main,
    width: "100%",
    padding: `0 !important`,
    margin: 0,
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: "none",
    "& .MuiAccordionSummary-root": {
      padding: `0 ${pxToRem(8)} !important`,
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 0px 12px",
    },
    '& .collapse_summary': {
      borderRadius: borders.borderRadius.md,
      backgroundColor: palette.transparent.main,
      color: palette.text.main,
      minHeight: "53px !important",
      [breakpoints.up("xl")]: {
        transition: transitions.create("box-shadow", {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.shorter,
        }),
      },
      "&> .MuiAccordionSummary-content": {
        alignItems: "center",
        margin: "0",
      },
      '& .collapse_iconBox': {
        background: palette.light.main,
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        borderRadius: borders.borderRadius.md,
        display: "grid",
        placeItems: "center",
        boxShadow: boxShadows.regular,
        transition: transitions.create("margin", {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
        "& svg, svg g": {
          width: 19,
          fill: palette.text.light,
        },
      },
      '& .collapse_text': {
        marginLeft: pxToRem(8),
        [breakpoints.up("xl")]: {
          opacity: 1,
          maxWidth: "100%",
          marginLeft: pxToRem(8),
          transition: transitions.create(["opacity", "margin"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },

        "& span": {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 0,
          color: palette.text.main,
        },
      },
      '&.active': {
        [breakpoints.up("xl")]: {
          boxShadow: boxShadows.xxl,
        },
        '& .collapse_iconBox': {
          background: palette[sidenavColor].main,
          "& svg, svg g": {
            fill: palette.light.main,
          },
        },
        '& .collapse_text span': {
          fontWeight: 600,
        },
      },
    },

  }
});

export const SubLinkStyled = styled(Box)(({ theme }) => {
  const { functions, palette, breakpoints, borders, transitions } = theme;
  const { pxToRem } = functions;
  return {
    display: "flex",
    alignItems: "center",
    borderRadius: borders.borderRadius.md,
    backgroundColor: palette.transparent.main,
    width: "100%",
    margin: "3px 0",
    padding: "10px 8px",
    paddingLeft: "12px",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: "none",
    '& .collapse_iconBox': {
      background: palette.transparent.main,
      minWidth: "auto",
      '& .active-icon': {
        display: 'none',
      },
    },
    '& .collapse_text': {
      marginLeft: pxToRem(8),

      [breakpoints.up("xl")]: {
        opacity: 1,
        maxWidth: "100%",
        marginLeft: pxToRem(8),
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      "& span": {
        fontWeight: 400,
        fontSize: 13,
        lineHeight: 0,
        color: palette.text.main,
      },
    },
    '&.active': {
      backgroundColor: "#E6EDF0",
      '& .collapse_iconBox': {
        '& .active-icon': {
          display: 'inline-block',
        },
        '& .icon': {
          display: 'none',
        },
      },
      '& .collapse_text': {
        "& span": {
          fontWeight: 500,
        },
      },
    },
  }
});

export const DashboardLayout = styled((props) => <Box {...props} component={'main'} />)(({ theme }) => {
  const { breakpoints, functions, transitions } = theme;
  const { pxToRem } = functions;
  return {
    position: "relative",
    padding: `0 ${pxToRem(10)} ${pxToRem(16)}`,
    [breakpoints.up("xl")]: {
      padding: pxToRem(24),
      paddingTop: 0,
      marginLeft: pxToRem(250),
      transition: transitions.create(["margin-left"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
  }
})