import colors from "theme/base/colors";
import typography from "theme/base/typography";


const { grey } = colors;
const { size } = typography;

export default {
  styleOverrides: {
    li: {
      lineHeight: 0,
      '& .MuiTypography-root': {
        textTransform: 'unset',
      }
    },

    separator: {
      fontSize: size.sm,
      color: grey[600],
    },
  },
};
