import logoPrimary from 'assets/images/logo-primary.png';
import logoLight from 'assets/images/logo-light.png';
import CalendarViewMonthTwoToneIcon from '@mui/icons-material/CalendarViewMonthTwoTone';
import CollectionsBookmarkTwoToneIcon from '@mui/icons-material/CollectionsBookmarkTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import levelOne from "assets/icons/level-1.svg";
import levelTwo from "assets/icons/level-2.svg";
import levelThree from "assets/icons/level-3.svg";
import CustomerSupport from "assets/icons/CustomerSupport";
import Shop from "assets/icons/Shop";
import SpaceShip from "assets/icons/SpaceShip";
// import ClassTwoToneIcon from '@mui/icons-material/ClassTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';

export const adminPanelInfo = {
  logoPrimary,
  logoLight,
  appName: 'Agility Sports',
  dataLimit: 10,
  currency: "$",
  dataLimitDate: "2024-01-01",
};

export const sidenavData = [
  {
    id: 1,
    type: "link",
    label: "Dashboard",
    link: "/dashboard",
    icon: <Shop size="14px" />,
  },
  {
    id: 2,
    type: "link",
    label: "Customers",
    link: "/customers",
    icon: <PeopleAltTwoToneIcon />,
  },
  {
    id: 3,
    type: "collapse",
    label: "Courts Manangement",
    icon: <CalendarViewMonthTwoToneIcon />,
    sub_menu: [
      { id: 3.1, order: 1, label: "Venues", link: "/venues" },
      { id: 3.2, order: 2, label: "Special Dates", link: "/special-dates" },
      { id: 3.3, order: 3, label: "Badminton", type: 'title' },
      { id: 3.4, order: 4, label: "Courts", link: "/badminton-courts" },
      { id: 3.5, order: 5, label: "Time Slots", link: "/badminton-time-slots" },
      { id: 3.6, order: 6, label: "Cricket", type: 'title' },
      { id: 3.7, order: 7, label: "Lanes", link: "/cricket-lanes" },
      { id: 3.8, order: 8, label: "Time Slots", link: "/cricket-time-slots" },
      // { id: 3.9, order: 9, label: "Snooker Tables", type: 'title' },
      // { id: "3.10", order: 10, label: "Tables", link: "/snooker-tables" },
      // { id: 3.11, order: 11, label: "Time Slots", link: "/snooker-tables-time-slots" },
    ],
  },
  {
    id: 4,
    type: "collapse",
    label: "Booking Manangement",
    icon: <CollectionsBookmarkTwoToneIcon />,
    sub_menu: [
      { id: 4.1, order: 1, label: "Court Bookings", link: "/court-bookings" },
      { id: 4.2, order: 2, label: "Bulk Court Booking", link: "/bulk-court-booking" },
      { id: 4.3, order: 3, label: "Court Booking Scheduler", link: "/court-booking-scheduler" },
    ],
  },
  {
    id: 5,
    type: "link",
    label: "Plans Membership",
    link: "/membership",
    icon: <LocalAtmTwoToneIcon />,
  },
  // {
  //   id: 6,
  //   type: "collapse",
  //   label: "Training Manangement",
  //   icon: <ClassTwoToneIcon />,
  //   sub_menu: [
  //     { id: 6.1, order: 1, label: "Coaches", link: "/coaches" },
  //     { id: 6.2, order: 2, label: "Trainings", link: "/training" },
  //     { id: 6.3, order: 3, label: "Training Category", link: "/training-category" },
  //   ],
  // },
  { id: 7, type: "title", label: "Account Settings" },
  {
    id: 8,
    type: "link",
    label: "Profile",
    link: "/profile",
    icon: <CustomerSupport size="14px" />,
  },
  {
    id: 9,
    type: "link",
    label: "Logout",
    link: "/logout",
    icon: <SpaceShip size="14px" color="error" />,
  },
];

export const expertiseData = [
  {
    value: 1,
    label: "Beginner",
  },
  {
    value: 2,
    label: "Intermediate",
  },
  {
    value: 3,
    label: "Master",
  },
];

export const enrolledForData = [
  {
    value: 1,
    label: 'Court Rental',
  },
  {
    value: 2,
    label: 'Coaching',
  },
];

export const planImg = {
  "1": levelOne,
  "2": levelTwo,
  "3": levelThree,
}

export const weekDays = [
  {
    id: 0,
    label: 'Sun'
  },
  {
    id: 1,
    label: 'Mon'
  },
  {
    id: 2,
    label: 'Tue'
  },
  {
    id: 3,
    label: 'Wed'
  },
  {
    id: 4,
    label: 'Thu'
  },
  {
    id: 5,
    label: 'Fri'
  },
  {
    id: 6,
    label: 'Sat'
  },
];

export const sports = [
  {
    label: "Badminton",
    id: 1,
  },
  {
    label: "Cricket",
    id: 2,
  },
  // {
  //   label: "Snooker Tables",
  //   id: 3,
  // },
]

// {
//   id: "1",
//   label: "Badminton",
//   sportArea: "Court",
// },
// {
//   id: "2",
//   label: "Cricket",
//   sportArea: "Lane",
// }
// {
//     id: "3",
//     label: "Snooker Table",
//     sportArea: "Table",
// },