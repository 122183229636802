import { Box } from '@mui/material';
import DashboardNavbar from 'common/dashboard-navbar';
import InternetNotAvailableSnackbar from 'common/no-internet-alert';
import Preloader from 'common/preloader';
import Sidenav from 'common/sidnav';
import { DashboardLayout } from 'common/sidnav/style';
import { ResetScroll } from 'helper';
import NotFound from 'pages/not-found';
import { Suspense } from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

const AuthUserRoot = () => {
  const { isAuthenticated } = useSelector(state => state.auth);
  if (!isAuthenticated) {
    return <NotFound buttonText="Go Back To Login" to={"/"} />;
  }
  return (
    <>
      <ResetScroll />
      <Sidenav />
      <InternetNotAvailableSnackbar />
      <DashboardLayout>
        <DashboardNavbar />
        <Suspense fallback={<Preloader custonClass='auth' />}>
          <Box py={2}>
            <Outlet />
          </Box>
          {/* <Footer /> */}
        </Suspense>
      </DashboardLayout>
    </>
  )
}

export default AuthUserRoot;