import { Box, IconButton, Toolbar, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import Breadcrumbs from "components/breadcrumbs";
import { cloneElement } from "react";
import { useLocation } from "react-router-dom";
import { AppBarStyled } from "./style";
import Profiler from "./Profiler";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "app/features/uiCtrlSlice";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const DashboardNavbar = (props) => {
  const { menu } = useSelector((state => state.uiCtrl));
  const dispatch = useDispatch();
  const theme = useTheme();
  const mini = useMediaQuery(theme.breakpoints.down('xl'));
  const { pathname } = useLocation();
  const route = pathname.split("/").slice(1);

  const handleMiniSidenav = () => {
    dispatch(setMenu(!menu))
  }
  return (
    <>
      <ElevationScroll {...props}>
        <AppBarStyled>
          <Toolbar>
            <Box mr="auto" mb={{ xs: 1, md: 0 }}>
              <Breadcrumbs
                title={route[route.length - (route.length < 3 ? 1 : 2)]}
                route={route}
              />
            </Box>
            {mini && (
              <IconButton
                size="small"
                color="inherit"
                disableRipple
                onClick={handleMiniSidenav}
                sx={{ mr: 1 }}
              >
                {menu ? <MenuOpenIcon fontSize="20px" /> : <MenuIcon fontSize="20px" />}
              </IconButton>
            )}
            <Box>
              <Profiler />
            </Box>
          </Toolbar>
        </AppBarStyled>
      </ElevationScroll>
      <Toolbar />
    </>
  );
};

export default DashboardNavbar;
