/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";

// import PagesRootLayout from "common/root-layouts/PagesRootLayout";
import RootLayoutCover from "common/root-layouts/RootLayoutCover";
import AuthUserRoot from "common/root-layouts/AuthUserRoot";
import Logout from "pages/authentication/logout";
import { Outlet } from "react-router-dom";
import NotFound from "pages/not-found";

// authentication
const LogIn = lazy(() => import("pages/authentication/login"));
const VerifyOTP = lazy(() => import("pages/authentication/verify-otp"));
const ForgotPassword = lazy(() => import("pages/authentication/forgot-password"));
const ResetPassword = lazy(() => import("pages/authentication/reset-password"));

// Pages
const Dashboard = lazy(() => import("pages/dashboard"));
const CustomersTable = lazy(() => import("pages/customer/CustomersTable"));

const CourtsTableLayout = lazy(() => import("pages/courts-manangement/courts/CourtsTableLayout"));
const CourtForm = lazy(() => import("pages/courts-manangement/courts/CourtForm"));

const TimeSlotsTable = lazy(() => import("pages/courts-manangement/time-slots/TimeSlotsTable"));
const TimeSlotForm = lazy(() => import("pages/courts-manangement/time-slots/TimeSlotForm"));

const SpecialDateTable = lazy(() => import("pages/courts-manangement/special-dates/SpecialDateTable"));
const SpecialDateForm = lazy(() => import("pages/courts-manangement/special-dates/SpecialDateForm"));

const VenuesTable = lazy(() => import("pages/courts-manangement/venues/VenuesTable"));
const VenueForm = lazy(() => import("pages/courts-manangement/venues/VenueForm"));
const CourtBookingsTable = lazy(() => import("pages/booking-management/court-bookings/CourtBookingsTable"));
const BulkBookingsTable = lazy(() => import("pages/booking-management/bulk-court-booking/BulkBookingsTable"));
const BulkCourtBooking = lazy(() => import("pages/booking-management/bulk-court-booking"));
const CourtBookingScheduler = lazy(() => import("pages/booking-management/court-booking-scheduler"));

const MembershipTable = lazy(() => import("pages/plans-management/membership/MembershipTable"));
const MembershipForm = lazy(() => import("pages/plans-management/membership/MembershipForm"));

const CoachesTable = lazy(() => import("pages/training-manangement/coach/CoachesTable"));
const CoachForm = lazy(() => import("pages/training-manangement/coach/CoachForm"));

const TrainingTable = lazy(() => import("pages/training-manangement/training/TrainingTable"));
const TrainingForm = lazy(() => import("pages/training-manangement/training/TrainingForm"));

const TrainingCategoryTable = lazy(() => import("pages/training-manangement/training-category/TrainingCategoryTable"));
const TrainingCategoryForm = lazy(() => import("pages/training-manangement/training-category/TrainingCategoryForm"));

const Profile = lazy(() => import("pages/profile"));
import ProfileDetails from "pages/profile/components/ProfileDetails";
import ChangePassword from "pages/profile/components/ChangePassword";


const routes = [
  {
    path: "/",
    element: <Outlet />,
    errorElement: <NotFound />,
    children: [
      {
        index: "",
        element: <RootLayoutCover />,
        children: [
          {
            index: true,
            element: <LogIn />
          },
          {
            path: 'verify-otp',
            element: <VerifyOTP />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'reset-password',
            element: <ResetPassword />
          },
        ]
      },
      {
        path: "",
        element: <AuthUserRoot />,
        // errorElement: <NotFound />,
        children: [
          {
            path: 'dashboard',
            element: <Dashboard />
          },
          {
            path: 'customers',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CustomersTable />,
              },
              // {
              //   path: 'add-new-customer',
              //   element: <CustomerForm />,
              // },
              // {
              //   path: 'edit-customer/:id',
              //   element: <CustomerForm />,
              // },
            ]
          },
          {
            path: 'bulk-court-booking',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <BulkBookingsTable />,
              },
              {
                path: 'add-new-bulk-booking',
                element: <BulkCourtBooking />,
              },
            ]
          },
          {
            path: 'court-booking-scheduler',
            element: <CourtBookingScheduler />,
          },
          {
            path: 'badminton-courts',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CourtsTableLayout sportType={1} />,
              },
              {
                path: 'add-new-court',
                element: <CourtForm sportType={1} />,
              },
              {
                path: 'edit-court/:id',
                element: <CourtForm sportType={1} />,
              },
            ]
          },
          {
            path: 'cricket-lanes',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CourtsTableLayout sportType={2} />,
              },
              {
                path: 'add-new-lane',
                element: <CourtForm sportType={2} />,
              },
              {
                path: 'edit-lane/:id',
                element: <CourtForm sportType={2} />,
              },
            ]
          },
          // {
          //   path: 'snooker-tables',
          //   element: <Outlet />,
          //   children: [
          //     {
          //       index: true,
          //       element: <CourtsTableLayout sportType={3} />,
          //     },
          //     {
          //       path: 'add-new-table',
          //       element: <CourtForm sportType={3} />,
          //     },
          //     {
          //       path: 'edit-table/:id',
          //       element: <CourtForm sportType={3} />,
          //     },
          //   ]
          // },
          {
            path: 'badminton-time-slots',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TimeSlotsTable sportType={1} />,
              },
              {
                path: 'add-new-slot',
                element: <TimeSlotForm sportType={1} />,
              },
              {
                path: 'edit-time-slot/:id',
                element: <TimeSlotForm sportType={1} />,
              },
            ]
          },
          {
            path: 'cricket-time-slots',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TimeSlotsTable sportType={2} />,
              },
              {
                path: 'add-new-slot',
                element: <TimeSlotForm sportType={2} />,
              },
              {
                path: 'edit-time-slot/:id',
                element: <TimeSlotForm sportType={2} />,
              },
            ]
          },
          // {
          //   path: 'snooker-tables-time-slots',
          //   element: <Outlet />,
          //   children: [
          //     {
          //       index: true,
          //       element: <TimeSlotsTable sportType={3} />,
          //     },
          //     {
          //       path: 'add-new-slot',
          //       element: <TimeSlotForm sportType={3} />,
          //     },
          //     {
          //       path: 'edit-time-slot/:id',
          //       element: <TimeSlotForm sportType={3} />,
          //     },
          //   ]
          // },
          {
            path: 'venues',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <VenuesTable />,
              },
              {
                path: 'add-new-venue',
                element: <VenueForm />,
              },
              {
                path: 'edit-venue/:id',
                element: <VenueForm />,
              },
            ]
          },
          {
            path: 'special-dates',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <SpecialDateTable />,
              },
              {
                path: 'add-special-date',
                element: <SpecialDateForm />,
              },
              {
                path: 'edit-special-date/:id',
                element: <SpecialDateForm />,
              },
            ]
          },
          {
            path: 'membership',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <MembershipTable />,
              },
              {
                path: 'add-new-membership',
                element: <MembershipForm />,
              },
              {
                path: 'edit-membership/:id',
                element: <MembershipForm />,
              },
            ]
          },
          {
            path: 'coaches',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CoachesTable />,
              },
              {
                path: 'add-new-coach',
                element: <CoachForm />,
              },
              {
                path: 'edit-coach/:id',
                element: <CoachForm />,
              },
            ]
          },
          {
            path: 'training',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TrainingTable />,
              },
              {
                path: 'add-new-training',
                element: <TrainingForm />,
              },
              {
                path: 'edit-training/:id',
                element: <TrainingForm />,
              },
            ]
          },
          {
            path: 'training-category',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TrainingCategoryTable />,
              },
              {
                path: 'add-new-training-category',
                element: <TrainingCategoryForm />,
              },
              {
                path: 'edit-training-category/:id',
                element: <TrainingCategoryForm />,
              },
            ]
          },
          {
            path: 'court-bookings',
            element: <CourtBookingsTable />,
          },
          {
            path: 'profile',
            element: <Profile />,
            children: [
              {
                element: <ProfileDetails />,
                index: true,
              },
              {
                element: <ChangePassword />,
                path: "change-password",
              },
            ],
          },
          {
            path: 'logout',
            element: <Logout />,
          },
        ]
      },
    ]
  },

  
];

export default routes;
