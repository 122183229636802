import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { MuiBreadcrumbsStyled } from "./style";
import { breadcrumbsHeadingGen } from "helper";

const Breadcrumbs = ({ title, route }) => {
  let routes = route.map((item) => {
    if (isNaN(+item)) {
      return breadcrumbsHeadingGen(item.split("-").join(" "));
    }
  });
  routes = routes.slice(0, route.length < 3 ? -1 : -2);
  const heading = isNaN(+title)
    ? breadcrumbsHeadingGen(title.split("-").join(" "))
    : routes[routes.length - 1];
  return (
    <Box>
      <MuiBreadcrumbsStyled>
        <Link to="/dashboard">
          <Typography
            component="span"
            variant="body2"
            lineHeight={0}
            sx={{ opacity: 0.8 }}
          >
            <HomeIcon fontSize="inherit" />
          </Typography>
        </Link>
        {routes.map(
          (el) =>
            heading !== el && (
              <Link
                to={`/${el.split(" ").join("-")}`.toLowerCase()}
                key={el}
                className="decoration-none"
              >
                <Typography
                  component="span"
                  variant="body2"
                  lineHeight={0}
                  sx={{ opacity: 0.8 }}
                >
                  {el}
                </Typography>
              </Link>
            )
        )}
        <Typography
          variant="button"
          fontWeight="400"
          color={'text.main'}
          lineHeight={0}
        >
          {heading}
        </Typography>
      </MuiBreadcrumbsStyled>
      <Typography
        fontWeight="bold"
        variant="h6"
        color={'text.main'}
        noWrap
        mt={0}
      >
        {heading}
      </Typography>
    </Box>
  );
}

export default Breadcrumbs;
