import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";

const { error, text } = colors;

export default {
  styleOverrides: {
    root: {
      display: "inline-block",
      fontSize: pxToRem(14),
      fontWeight: 400,
      color: text.main,
      marginBottom: 6,
    },
    asterisk: {
      color: error.focus,
    },
  },
};
