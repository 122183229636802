import { differenceInYears } from "date-fns";
import dayjs from "dayjs";
import { removeHtmlTags } from "helper";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
const SUPPOERTED_IMAGE = ["image/jpeg", "image/jpg", "image/png"];
const requiredMassage = "This Field required!";
const onlyAlphabet = /^[aA-zZ\s]+$/;
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const zip_codeRegex =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i;
const emailPattern =
  /(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;
const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!@#$%^&*(),.?":{}|<>])[A-Za-z\d@$!%*?&!@#$%^&*(),.?":{}|<>]+$/g;
const minCr = 2;
const maxCr = 20;

export const fileValidationSchema = yup.addMethod(
  yup.mixed,
  "fileValidationSchema",
  function (options = {}) {
    const {
      allowedFileTypes = [], // Array of allowed MIME types, e.g. ['image/jpeg', 'application/pdf']
      maxFileSize = 0, // Max file size in MB, 0 means no restriction
      minFileSize = 0, // Min file size in MB, 0 means no restriction
      maxDimensions = null, // Max dimensions for images { width: 1920, height: 1080 }
      minDimensions = null, // Min dimensions for images { width: 100, height: 100 }
      errorMessages = {}, // Custom error messages object
    } = options;

    const defaultMessages = {
      fileType: "Unsupported file type.",
      fileSize: "File is too large.",
      minFileSize: "File is too small.",
      fileDimensions: "Invalid file dimensions.",
    };

    const mergedMessages = { ...defaultMessages, ...errorMessages };

    const baseSchema = yup
      .mixed()
      .test("fileType", mergedMessages.fileType, (value) => {
        if (!value || !value.type) return true; // If no file is uploaded
        return allowedFileTypes.length > 0
          ? allowedFileTypes.includes(value.type)
          : true;
      })
      .test("fileSize", mergedMessages.fileSize, (value) => {
        if (!value) return true; // If no file is uploaded
        return maxFileSize > 0 ? value.size <= maxFileSize * 1048576 : true;
      })
      .test("minFileSize", mergedMessages.minFileSize, (value) => {
        if (!value) return true; // If no file is uploaded
        return minFileSize > 0 ? value.size >= minFileSize * 1048576 : true;
      })
      .test("fileDimensions", mergedMessages.fileDimensions, async (value) => {
        if (!value || !value.type.startsWith("image")) return true; // Only check for images
        if (!minDimensions && !maxDimensions) return true;

        const imageDimensions = await getImageDimensions(value);
        if (minDimensions) {
          if (
            imageDimensions.width < minDimensions.width ||
            imageDimensions.height < minDimensions.height
          ) {
            return false;
          }
        }
        if (maxDimensions) {
          if (
            imageDimensions.width > maxDimensions.width ||
            imageDimensions.height > maxDimensions.height
          ) {
            return false;
          }
        }
        return true;
      });

    return baseSchema;
  }
);

const password = {
  new_password: yup
    .string()
    .required(requiredMassage)
    .min(8, "Your password must have at least 8 characters.")
    .matches(passwordPattern, "You have entered an invalid password format."),
  confirm_password: yup
    .string()
    .required(requiredMassage)
    .oneOf([yup.ref("new_password"), null], "Confirm password must be match."),
};

// Helper function to get image dimensions
const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
  });
};

export const loginWithPasswordSchema = yup.object({
  email: yup
    .string()
    .required(requiredMassage)
    .matches(emailPattern, "Please enter valid email address!"),
  password: yup
    .string()
    .required(requiredMassage)
    .min(8, "Your password must have at least 8 characters!")
    .matches(passwordPattern, "You have entered an invalid password format!"),
});

export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .required(requiredMassage)
    .matches(emailPattern, "Please enter valid email address!"),
});

export const forgotPasswordSchema = yup.object({
  ...password,
});

export const passwordChangeSchema = yup.object({
  old_password: yup
    .string()
    .required(requiredMassage)
    .min(8, "Your password must have at least 8 characters.")
    .matches(passwordPattern, "You have entered an invalid password format."),
  ...password,
});

export const timeSlotFormSchema = yup.object({
  currency: yup.string().required(requiredMassage),
  slot_time: yup.string().required(requiredMassage),
  venue_id: yup.string().required(requiredMassage),
  price: yup
    .number()
    .required(requiredMassage)
    .min(1, "Price must be greater than 0!"),
  pricing: yup.array().of(
    yup.object().shape({
      week_day: yup.number().required(requiredMassage),
      price: yup
        .number()
        .required(requiredMassage)
        .min(1, "Price must be greater than 0!"),
    })
  ),
});

export const courtFormSchema = yup.object({
  venue_id: yup.string().required(requiredMassage),
  court_name: yup
    .string()
    .required(requiredMassage)
    .matches(
      alphaNumeric,
      "Only alphabet and numeric are allowed for this field."
    ),
  order: yup.number().required(requiredMassage),
});

export const bulkBookingSchema = yup.object({
  venue_id: yup.string().required(requiredMassage),
  court_id: yup.string().required(requiredMassage),
  booking_start_date: yup.date().required("Booking date range is required"),
  booking_end_date: yup.date(),
  slot_time: yup.string().nullable(),
  slot_id: yup
    .string()
    .required(requiredMassage)
    .test("is-future-time", "Slot time must not be in the past", function () {
      const { booking_start_date, slot_time } = this.parent;
      const dateTimeString = `${dayjs(booking_start_date).format(
        "YYYY-MM-DD"
      )}T${slot_time}`;
      const dateTime = dayjs(dateTimeString);
      return dateTime.isAfter(dayjs());
    }),
  email: yup
    .string()
    .required(requiredMassage)
    .matches(emailPattern, "Please enter valid email address!"),
  first_name: yup
    .string()
    .required(requiredMassage)
    .min(minCr, `First name must have at least ${minCr} characters.`)
    .max(maxCr, `First name may not be longer then ${maxCr} characters.`)
    .matches(onlyAlphabet, "Only alphabet are allowed for this field."),
  last_name: yup
    .string()
    .min(minCr, `Last name must have at least ${minCr} characters.`)
    .max(maxCr, `Last name may not be longer then ${maxCr} characters.`)
    .matches(onlyAlphabet, "Only alphabet are allowed for this field.")
    .nullable(),
  // iso_country_code: yup.string().required(requiredMassage),
  // country_code: yup.string().required(requiredMassage),
  // phone_number: yup
  //   .string()
  //   .required(requiredMassage)
  //   .test("is-phone-number", "Phone Number is not valid!", function (val) {
  //     const { country_code } = this.parent;
  //     const phoneNumber = country_code.concat(val);
  //     return isValidPhoneNumber(phoneNumber);
  //   }),
  iso_country_code: yup.string().optional(),
  country_code: yup.string().optional(),
  phone_number: yup
    .string()
    .optional()
    .test("is-phone-number", "Phone Number is not valid!", function (val) {
      const { country_code } = this.parent;
      const phoneNumber = country_code.concat(val);
      return val ? isValidPhoneNumber(phoneNumber) : true;
    }),
  note: yup
    .string()
    .optional()
    .max(500, "Note shouldn't be longer the 500 characters.")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
});

export const schedulerSchema = yup.object({
  venue_id: yup.string().required(requiredMassage),
  court_id: yup.string().required(requiredMassage),
  booking_start_date: yup.date().required("Booking date range is required"),
  booking_end_date: yup.date(),
  slot_time: yup.string().nullable(),
  slot_id: yup.string().required(requiredMassage),
  email: yup
    .string()
    .required(requiredMassage)
    .matches(emailPattern, "Please enter valid email address!"),
  first_name: yup
    .string()
    .required(requiredMassage)
    .min(minCr, `First name must have at least ${minCr} characters.`)
    .max(maxCr, `First name may not be longer then ${maxCr} characters.`)
    .matches(onlyAlphabet, "Only alphabet are allowed for this field."),
  last_name: yup
    .string()
    .min(minCr, `Last name must have at least ${minCr} characters.`)
    .max(maxCr, `Last name may not be longer then ${maxCr} characters.`)
    .matches(onlyAlphabet, "Only alphabet are allowed for this field.")
    .nullable(),
  // iso_country_code: yup.string().required(requiredMassage),
  // country_code: yup.string().required(requiredMassage),
  // phone_number: yup
  //   .string()
  //   .required(requiredMassage)
  //   .test("is-phone-number", "Phone Number is not valid!", function (val) {
  //     const { country_code } = this.parent;
  //     const phoneNumber = country_code.concat(val);
  //     return isValidPhoneNumber(phoneNumber);
  //   }),
  iso_country_code: yup.string().optional(),
  country_code: yup.string().optional(),
  phone_number: yup
    .string()
    .optional()
    .test("is-phone-number", "Phone Number is not valid!", function (val) {
      const { country_code } = this.parent;
      const phoneNumber = country_code.concat(val);
      return val ? isValidPhoneNumber(phoneNumber) : true;
    }),
  note: yup
    .string()
    .optional()
    .max(500, "Note shouldn't be longer the 500 characters.")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
});

export const venueFormSchema = yup.object({
  image: yup.string().required("Please upload a venue image."),
  url: yup.mixed().when("image", {
    is: (image) => image?.split("/")[0] !== "https:",
    then: (schema) =>
      schema.fileValidationSchema({
        allowedFileTypes: SUPPOERTED_IMAGE, // Specify allowed file types
        minDimensions: { width: 417, height: 249 }, // Set the min dimensions for the image
        maxFileSize: 7,
        minFileSize: 0.0002384,
        errorMessages: {
          fileType: "Please upload a venue image in jpg, jpeg, or png format.",
          fileSize:
            "Venue image is too large. Image shouldn't be greater then 7MB!",
          minFileSize:
            "Venue image too small. Image shouldn't be less then 250 KB!",
          fileDimensions: "Minimum venue image dimensions should be 417 x 249.",
        },
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  venue_name: yup
    .string()
    .required(requiredMassage)
    .test("venueNameRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
  is_coming_soon: yup.number().required(requiredMassage),
  availability_date: yup
    .date()
    .required(requiredMassage)
    .when("is_coming_soon", {
      is: (is_coming_soon) => Boolean(is_coming_soon),
      then: (schema) => schema.notRequired().nullable(),
    }),
  description: yup
    .string()
    .required(requiredMassage)
    .max(5000, "Description shouldn't be longer the 5000 characters.")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
  location: yup
    .string()
    .required(requiredMassage)
    .test("locationRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
  availabitity_tag: yup
    .string()
    .required(requiredMassage)
    .test(
      "availabitityTagRequired",
      "HTML tags and script not allow.",
      (item) => {
        return removeHtmlTags(item);
      }
    ),
  available_sport_types: yup
    .array()
    .of(yup.string())
    .min(1, "At least one sport must be selected.")
    .required("At least one sport must be selected."),
});

export const membershipFormSchema = yup.object({
  membership_name: yup
    .string()
    .required(requiredMassage)
    .min(minCr, `membership name must have at least ${minCr} characters.`)
    .max(50, `membership name may not be longer then 50 characters.`)
    .matches(
      alphaNumeric,
      "Only alphabet and numeric are allowed for this field."
    ),
  price: yup
    .number()
    .required(requiredMassage)
    .min(1, "Price must be greater than 0!"),
  level: yup.number().required(requiredMassage),
  max_slots_can_avail: yup
    .number()
    .required(requiredMassage)
    .min(1, "Max slots can avail must be greater than 0!"),
  discount: yup
    .number()
    .required(requiredMassage)
    .when("price", (price, schema) => {
      return schema.max(
        price - 0.01,
        "Discount cannot be greater than or equal to the price!"
      );
    }),
  is_group_plan: yup.number().oneOf([0, 1]).optional(),
  group_label: yup.string().when("is_group_plan", {
    is: (is_group_plan) => is_group_plan === 1,
    then: (schema) => schema.required(requiredMassage),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  discount_availing_start_time: yup.string().required(requiredMassage),
  discount_availing_end_time: yup
    .string()
    .required(requiredMassage)
    .test(
      "end-time-validation",
      "Discount availing end time should be equal to or greater than Discount availing start time",
      function (value) {
        const { discount_availing_start_time } = this.parent;
        if (!discount_availing_start_time || !value) return true;
        const startTime = discount_availing_start_time.split(":").map(Number);
        const endTime = value.split(":").map(Number);
        // Compare hours and minutes
        return (
          endTime[0] > startTime[0] ||
          (endTime[0] === startTime[0] && endTime[1] >= startTime[1])
        );
      }
    ),
  features: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required(requiredMassage)
        .test("nameRequired", "HTML tags and script not allow.", (item) => {
          return removeHtmlTags(item);
        }),
      value: yup
        .number()
        .required(requiredMassage)
        .max(100, "value cannot be greater then 100!"),
      status: yup.number(),
    })
  ),
  important_note: yup
    .string()
    .required(requiredMassage)
    .max(200, "important note shouldn't be longer the 200 characters.")
    .test(
      "importantNoteRequired",
      "HTML tags and script not allow.",
      (item) => {
        return removeHtmlTags(item);
      }
    ),
});

export const specialDateFormSchema = yup.object({
  date: yup
    .date()
    .min(new Date(), "Date can't be in the past")
    .required(requiredMassage),
  price: yup
    .number()
    .required(requiredMassage)
    .min(1, "Price must be greater than 0!"),
  venue_id: yup.string().required(requiredMassage),
});

export const coachFormSchema = yup.object({
  profile_pic: yup.string().required("Please upload a coach image."),
  url: yup.mixed().when("image", {
    is: (image) => image?.split("/")[0] !== "https:",
    then: (schema) =>
      schema.fileValidationSchema({
        allowedFileTypes: SUPPOERTED_IMAGE, // Specify allowed file types
        minDimensions: { width: 500, height: 500 }, // Set the min dimensions for the image
        maxDimensions: { width: 1080, height: 1080 }, // Set the max dimensions for the image
        maxFileSize: 7,
        minFileSize: 0.0002384,
        errorMessages: {
          fileType: "Please upload a coach image in jpg, jpeg, or png format.",
          fileSize:
            "Coach image is too large. Image shouldn't be greater then 7MB!",
          minFileSize:
            "Coach image too small. Image shouldn't be less then 250 KB!",
          fileDimensions:
            "Minimum coach image dimensions should be 500 x 500. & Maximum 1080 x 1080",
        },
      }),

    otherwise: (schema) => schema.notRequired(),
  }),
  coach_name: yup
    .string()
    .required(requiredMassage)
    .min(minCr, `coach name must have at least ${minCr} characters.`)
    .max(50, `coach name may not be longer then 50 characters.`)
    .matches(
      alphaNumeric,
      "Only alphabet and numeric are allowed for this field."
    ),
  country_iso_code: yup.string().required(requiredMassage),
  country_code: yup.string().required(requiredMassage),
  phone_number: yup
    .string()
    .required(requiredMassage)
    .test("is-phone-number", "Phone Number is not valid!", function (val) {
      const { country_code } = this.parent;
      const phoneNumber = country_code.concat(val);
      return val ? isValidPhoneNumber(phoneNumber) : true;
    }),
  venue_id: yup.string().required(requiredMassage),
  coach_level: yup.string().required(requiredMassage),
  highest_tournaments_played: yup
    .array()
    .min(1, "At least one tournament is required!")
    .required(requiredMassage),
  awards: yup
    .array()
    .min(1, "At least one tournament is required!")
    .required(requiredMassage),
  address: yup
    .string()
    .required(requiredMassage)
    .max(150, "Maximum 150 characters are allowed!")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
  country: yup
    .string()
    .required(requiredMassage)
    .matches(onlyAlphabet, "Only alphabets are allowed in the Country Name!"),
  town: yup
    .string()
    .required(requiredMassage)
    .matches(
      onlyAlphabet,
      "Only alphabets are allowed in the State / Province Name!"
    ),
  // city: yup
  //   .string()
  //   .required(requiredMassage)
  //   .matches(onlyAlphabet, "Only alphabets are allowed in the City Name!"),
  zip_code: yup
    .string()
    .required(requiredMassage)
    .matches(zip_codeRegex, "Invalid Zipcode format!"),
  dob: yup
    .date()
    .required("Date of birth is required!")
    .test(
      "valid-date",
      "Invalid date format!",
      (value) => value instanceof Date && !isNaN(value.getTime())
    )
    .test(
      "age-limit",
      "You must be at least 18 years old!",
      (value) => differenceInYears(new Date(), new Date(value)) >= 18
    )
    .max(new Date(), "Date of birth cannot be in the future!"),
});

export const trainingFormSchema = yup.object({
  training_name: yup
    .string()
    .required(requiredMassage)
    .max(250, "Maximum 250 characters are allowed!")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
  training_level: yup.string().required(requiredMassage),
  training_type: yup.string().required(requiredMassage),
  venue_id: yup.string().required(requiredMassage),
  coach_id: yup.string().required(requiredMassage),
  training_category_id: yup.string().required(requiredMassage),
  total_seats: yup
    .number()
    .required(requiredMassage)
    .min(1, "Seats must be greater than 0!"),
  start_date: yup.date().required(requiredMassage),
  // end_date: yup.date().required(requiredMassage),
  // start_time: yup.string().required(requiredMassage),
  // end_time: yup
  //   .string()
  //   .required(requiredMassage)
  //   .test(
  //     "end-time-validation",
  //     "Discount availing end time should be equal to or greater than Discount availing start time",
  //     function (value) {
  //       const { start_time } = this.parent;
  //       if (!start_time || !value) return true;
  //       const startTime = start_time.split(":").map(Number);
  //       const endTime = value.split(":").map(Number);
  //       // Compare hours and minutes
  //       return (
  //         endTime[0] > startTime[0] ||
  //         (endTime[0] === startTime[0] && endTime[1] >= startTime[1])
  //       );
  //     }
  //   ),
  sessions_with_offer: yup.array().of(
    yup.object().shape({
      sessions: yup
        .string()
        .required(requiredMassage)
        .test("nameRequired", "HTML tags and script not allow.", (item) => {
          return removeHtmlTags(item);
        }),
      discount_percentage: yup
        .number()
        .required(requiredMassage)
        .max(100, "discount value cannot be greater then 100!"),
    })
  ),
  days: yup
    .array()
    .min(1, "At least select one day!")
    .required(requiredMassage),
  price: yup
    .number()
    .required(requiredMassage)
    .min(1, "Price must be greater than 0!"),
  order: yup.number().required(requiredMassage),
  description: yup
    .string()
    .required(requiredMassage)
    .max(5000, "Description shouldn't be longer the 5000 characters.")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
});

export const trainingCategoryFormSchema = yup.object({
  category_name: yup
    .string()
    .required(requiredMassage)
    .max(250, "Maximum 250 characters are allowed!")
    .test("descriptionRequired", "HTML tags and script not allow.", (item) => {
      return removeHtmlTags(item);
    }),
  training_type: yup.string().required(requiredMassage),
  coach_count: yup
    .number()
    .required(requiredMassage)
    .min(1, "Coach Count must be greater than 0!"),
  student_count: yup
    .number()
    .required(requiredMassage)
    .min(1, "Student Count must be greater than 0!"),
});
