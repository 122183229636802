
import { Box, Typography } from "@mui/material";
import { ProfileInfoCardStyled } from "./style";

const ProfileInfoCard = ({ title, info }) => {
  const labels = [];
  const values = [];

  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  Object.values(info).forEach((el) => values.push(el));

  const renderItems = labels.map((label, key) => (
    <ProfileInfoCardStyled key={label} display="flex" flexWrap="wrap" py={1} pr={2}>
      <Typography variant="button" className={'labels'}>
        {label}:&nbsp;
      </Typography>
      <Typography variant="button" className='values' color="text.light" >
        {values[key]}
      </Typography>
    </ProfileInfoCardStyled>
  ));

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Typography variant="h5" fontWeight="medium" textTransform="capitalize">
          {title}
        </Typography>
      </Box>
      <Box p={2}>
        <Box>{renderItems}</Box>
      </Box>
    </>
  );
};

export default ProfileInfoCard;
