import { useNavigate } from "react-router-dom";
import { Box, Button, FormLabel, Grid } from "@mui/material";
import { useFormik } from "formik";
import { passwordChangeSchema } from "Validation";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useAdminChangePasswordMutation } from "app/features/apis/AuthApi";
import { commonErrorShowFn } from "helper";
import PasswordInput from "components/password-input";
import useLogout from "pages/authentication/logout/useLogout";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const { isOffline } = useSelector(state => state.uiCtrl);

  const logout = useLogout();
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const [handleAdminChangePassword] = useAdminChangePasswordMutation();
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: passwordChangeSchema,
    onSubmit: async ({ old_password, new_password }, { resetForm, setSubmitting }) => {
      const updateData = {
        current_password: old_password,
        new_password,
      };
      setSubmitting(true);
      try {
        const res = await handleAdminChangePassword(updateData).unwrap();
        if (res?.code) {
          resetForm();
          suiSnackbar(res?.message, "success");
          logout();
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        commonErrorShowFn(error, suiSnackbar, isOffline);
      }
    },
  });

  return (
    <Grid container component="form" onSubmit={formik.handleSubmit} gap={1.5}>
      <Grid item xs={12} md={10} xl={8} xxl={6}>
        <FormLabel htmlFor="old_password" required>
          Current Password
        </FormLabel>
        <PasswordInput size="small" fullWidth placeholder="Enter Current Password" id="old_password"
          value={formik.values.old_password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.old_password && formik.errors.old_password)}
          helperText={formik.touched.old_password && formik.errors.old_password} />
      </Grid>
      <Grid item xs={12} md={10} xl={8} xxl={6}>
        <Box mb={2}>
          <FormLabel htmlFor="new_password" required>
            New Password
          </FormLabel>
          <PasswordInput size="small" fullWidth placeholder="Enter New Password" id="new_password"
            value={formik.values.new_password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            showValidationList
            error={Boolean(formik.touched.new_password && formik.errors.new_password)}
            helperText={formik.touched.new_password && formik.errors.new_password} />
        </Box>
      </Grid>
      <Grid item xs={12} md={10} xl={8} xxl={6} sx={{ marginBottom: "20px" }}>
        <FormLabel htmlFor="confirm_password" required>
          Confirm Password
        </FormLabel>
        <PasswordInput size="small" fullWidth placeholder="Enter Confirm Password" id="confirm_password"
          value={formik.values.confirm_password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.confirm_password && formik.errors.confirm_password)}
          helperText={formik.touched.confirm_password && formik.errors.confirm_password} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm="auto">
            <Button variant="contained" disabled={formik.isSubmitting} onClick={() => navigate("/profile")} color="error" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              type="submit"
              variant="contained"
              disabled={formik.isSubmitting || !formik.dirty}
              color="info"
              fullWidth
            >
              {formik.isSubmitting ? "Changing...." : "Change Password"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
